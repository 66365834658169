// extracted by mini-css-extract-plugin
export var categories = "style-module--categories--1lw2I";
export var screen = "style-module--screen--1jNEs";
export var screenOuter = "style-module--screenOuter--lRO2_";
export var tableIcon = "style-module--tableIcon--2waDL";
export var tableResponsive = "style-module--tableResponsive--1eWGx";
export var banner = "style-module--banner--2GcsL";
export var bannerFlex = "style-module--bannerFlex--19WHX";
export var bannerFlexInfo = "style-module--bannerFlexInfo--1hhTL";
export var logo = "style-module--logo--22fZx";
export var list = "style-module--list--LoSU_";
export var listSingle = "style-module--listSingle--3cd-2";
export var listDouble = "style-module--listDouble--3_5Ed";
export var listTitle = "style-module--listTitle--1y_bI";
export var listItem = "style-module--listItem--E0cpV";
export var listItemContainer = "style-module--listItemContainer--2DXw1";
export var listItemImage = "style-module--listItemImage--3J9OU";
export var listItemIcon = "style-module--listItemIcon--TzpBC";
export var listItemLogo = "style-module--listItemLogo--2Rpzo";
export var modal = "style-module--modal--2M7xb";
export var modalWrapper = "style-module--modalWrapper--33ll0";
export var modalLogo = "style-module--modalLogo--16bdL";
export var modalFooter = "style-module--modalFooter--2Ya9B";
export var modalLinks = "style-module--modalLinks--230lg";
export var modalLink = "style-module--modalLink--1-39M";
export var modalNft = "style-module--modalNft--1nmRo";